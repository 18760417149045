import React from "react";
import Toaster from "../views/toaster/Toaster";
import { CAlert } from "@coreui/react";

const showAlert = (
  condition: any,
  errors = [],
  message: string,
  Actions: Function
) => {
  if (!condition && (message || errors)) {
    return (
      <CAlert
        show
        closeButton
        onShowChange={Actions}
        className="m-0"
        color="danger"
      >
        {message || Object.values(errors)?.[0]?.[0]}
      </CAlert>
    );
  }
  if (condition) {
    return (
      <CAlert
        show
        closeButton
        onShowChange={Actions}
        className="m-0"
        color="success"
      >
        {message}
      </CAlert>
    );
  }
};

export default ({ Actions, condition, errors, message }: any) => {
  const check =
    !(message === null && errors === null) || (condition && message != null);
  const action = (val: any) => {
    if (check && !val) {
      Actions();
    }
  };
  return (
    <Toaster
      show={
        !(message === null && errors === null) || (condition && message != null)
      }
      message={showAlert(condition, errors, message, action)}
    />
  );
};
