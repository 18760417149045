import Actions from "../actions/actionTypes";
import { fiatTransactions } from "./initialState";

export default function (state = fiatTransactions, action: any) {
  switch (action.type) {
    case Actions.EDIT_FIATS_TRANSACTIONS:
      return {
        ...state,
        isLoading: false,
        errors: null,
        message: null,
        ...action.payload,
      };
    default:
      return state;
  }
}
