import actions, { ActionParam } from "./actionTypes";
import {
  clearLocalStorage,
  fetchData,
  setAuthToken,
  saveToLocalStorage,
  getLocalStorage,
  setToken,
  clearToken,
} from "../../utils";
import { LoginReq, AuthRes, VerifyEmailReq } from "../../models/Auth";

export const AuthActions = (payload = {}, type = actions.EDIT_AUTH) => ({
  type,
  payload,
});

export const SignOutAsync = () => (dispatch: any) => {
  dispatch(AuthActions({ isLoading: true }));
  clearToken();
  clearLocalStorage({ key: "user" });
  clearLocalStorage({ key: "permissions" });
  dispatch(
    AuthActions({
      user: null,
      isSettingAuth: false,
      isAuthenticated: false,
    })
  );
};

export const GetUserAsync = () => (dispatch: any) => {
  const user = getLocalStorage({ key: "user", isJson: true });

  setAuthToken(user ? user.token : null);
  if (user) {
    const { profile, ...auth } = user;
    dispatch(
      AuthActions({
        user: auth,
        isSettingAuth: false,
        isAuthenticated: true,
      })
    );
  } else {
    dispatch(
      AuthActions({
        user: null,
        isSettingAuth: false,
        isAuthenticated: false,
      })
    );
  }
};

export const GetMemberDetailsAsync =
  ({ cb, params }: ActionParam) =>
  async (dispatch: Function, getState: Function) => {
    const { data, error }: { data?: any; error?: any } = await fetchData({
      method: "GET",
      host: process.env.REACT_APP_SERVER,
      path: `auth/user-detail/${params.id}`,
      model: (res: any) => res,
    });
    if (data) {
      cb?.(data);
    } else {
      cb?.(null, error);
    }
  };

export const GetMyRolesDetailsAsync =
  ({ cb, params }: ActionParam) =>
  async (dispatch: Function, getState: Function) => {
    const { data, error }: { data?: any; error?: any } = await fetchData({
      method: "GET",
      host: process.env.REACT_APP_SERVER,
      path: `auth/permissions`,
      model: (res: any) => res,
    });
    if (data) {
      // saveToLocalStorage({ obj: data, key: "permissions", isJson: false });
      cb?.(data);
    } else {
      cb?.(null, error);
    }
  };

export const LogInAsync = (payload: any) => async (dispatch: any) => {
  dispatch(AuthActions({ isLoading: true }));

  const { data, error }: { data?: any; error?: any } = await fetchData({
    method: "POST",
    path: "auth/login/admin",
    payload: LoginReq(payload),
    model: AuthRes,
  });

  if (data) {
    const user = { ...data };
    setToken(data.token);
    saveToLocalStorage({ obj: user, key: "user", isJson: true });
    setAuthToken(data.token);

    dispatch(
      AuthActions({
        user,
        isAuthenticated: true,
        isSettingAuth: false,
        errors: null,
        message: "Authentication Successful",
      })
    );
  } else if (error) {
    dispatch(
      AuthActions({
        errors: error.errors,
        message: error.message,
        user: null,
        isAuthenticated: false,
      })
    );
  }
};

export const VerifyEmailAsync = (payload: any) => async (dispatch: any) => {
  dispatch(AuthActions({ isLoading: true }));

  const { data, error }: { data?: any; error?: any } = await fetchData({
    method: "POST",
    path: "auth/admin/verify-email",
    payload: VerifyEmailReq(payload),
    model: AuthRes,
  });

  if (data) {
    const user = { ...data };
    setToken(data.token);
    saveToLocalStorage({ obj: user, key: "user", isJson: true });
    setAuthToken(data.token);

    dispatch(
      AuthActions({
        user,
        isAuthenticated: true,
        isSettingAuth: false,
        errors: null,
        message: "Verification Successful",
      })
    );
  } else if (error) {
    dispatch(
      AuthActions({
        errors: error.errors,
        message: error.message,
        user: null,
        isAuthenticated: false,
      })
    );
  }
};
