import { AuthState } from "../../models/Auth";
import { DashboardState } from "../../models/Dashboard";
import { TransactionState } from "../../models/Transaction";
import { UserState } from "../../models/Users";
import { TicketState } from "../../models/Ticket";
import { RefundState } from "../../models/Refund";
import { ChargeState } from "../../models/Charges";
import { RateState } from "../../models/Rates";
import { ResetPassState } from "../../models/PasswordReset";
import { OtpState } from "../../models/Otp"; 
import { FiatState, FiatTransactionsState } from "../../models/Fiats"; 
import { ReferralState } from "../../models/referrals";
import { DepartmentState } from "../../models/department";
import { PermissionState } from "../../models/Permission";
import { RoleState } from "../../models/Role";
import { ServiceState } from "../../models/Service";

export const auth: AuthState = {
  user: undefined,
  profile: undefined,
  isAuthenticated: false,
  errors: undefined,
  message: undefined,
  isSettingAuth: true,
  isLoading: false,
};

export const dashboard: DashboardState = {
  sidebarShow: "responsive",
  asideShow: false,
  darkMode: true,
  page: "Dashboard",
};

export const transactions: TransactionState = {
  tranxList: undefined,
  errors: null,
  message: undefined,
  isLoading: false,
};

export const users: UserState = {
  customerList: undefined,
  errors: null,
  message: undefined,
  isLoading: false,
};
export const tickets: TicketState = {
  ticketList: undefined,
  errors: null,
  message: undefined,
  isLoading: false,
};

export const refunds: RefundState = {
  refundList: undefined,
  errors: null,
  message: undefined,
  isLoading: false,
};

export const charges: ChargeState = {
  chargesList: undefined,
  errors: null,
  message: undefined,
  isLoading: false,
};

export const otp: OtpState = {
  errors: null,
  message: undefined,
  isLoading: false,
  sentOtp: false,
};

export const resetPass: ResetPassState = {
  resetToken: undefined,
  isPasswordReset: false,
  errors: null,
  message: undefined,
  isResetLinkSent: false,
  isLoading: false,
};

export interface IState {
  dashboard: DashboardState;
  otp: OtpState;
  resetPass: ResetPassState;
  transactions: TransactionState;
  users: UserState;
  tickets: TicketState;
  refunds: RefundState;
  charges: ChargeState;
  rates: RateState;
  auth: AuthState;
  fiats: FiatState;
  fiatTransactions: FiatTransactionsState;
  services: ServiceState;
  permissions: PermissionState;
  roles: RoleState;
}

export const rates: RateState = {
  rateList: undefined,
  errors: null,
  message: undefined,
  isLoading: false,
};

export const fiats: FiatState = {
  fiatList: undefined,
  errors: null,
  message: undefined,
  isLoading: false,
};

export const referral: FiatState = {
  fiatList: undefined,
  errors: null,
  message: undefined,
  isLoading: false,
};

export const fiatTransactions: FiatTransactionsState = {
  fiatTransactionsList: undefined,
  errors: null,
  message: undefined,
  isLoading: false,
};

export const referrals: ReferralState = {
  referralList: undefined,
  errors: null,
  message: undefined,
  isLoading: false,
};

export const departments: DepartmentState = {
  departmentList: undefined,
  errors: null,
  message: undefined,
  isLoading: false,
};

export const permissions: PermissionState = {
  permissionList: undefined,
  errors: null,
  message: undefined,
  isLoading: false,
};

export const roles: RoleState = {
  roleList: undefined,
  errors: null,
  message: undefined,
  isLoading: false,
};

export const services: ServiceState= {
  serviceList: undefined,
  errors: null,
  message: undefined,
  isLoading: false,
};
