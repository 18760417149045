import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { GetUserAsync } from "../redux/actions/authActions";
import { IState } from "../redux/reducer/initialState";

const Hydrator = ({ children }: { children: any }) => {
  const { isSettingAuth } = useSelector(({ auth }: IState) => auth);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(GetUserAsync());
  }, []);

  return isSettingAuth ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
      }}
    >
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  ) : (
    <main>{children}</main>
  );
};

export default Hydrator;
