import React, { Component } from "react";
import { HashRouter, Switch } from "react-router-dom";
import "./scss/style.scss";
import PublicRoute from "./shared/PublicRoute";
import PrivateRoute from "./shared/PrivateRoute";
import Hydrator from "./shared/Hydrator";
import ResetPassword from "./pages/resetPassword/ResetPassword";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containerss
const Login = React.lazy(() => import("./pages/login/Login"));

const VerifyEmail = React.lazy(() => import("./pages/verifyEmail/VerifyEmail"));

const EnterResetOTP = React.lazy(
  () => import("./pages/resetPassword/EnterResetOTP")
);
const ForgotPassword = React.lazy(
  () => import("./pages/resetPassword/ForgotPassword")
);
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

class App extends Component {
  render() {
    return (
      <Hydrator>
        <HashRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <PublicRoute
                exact
                path="/login"
                name="Login Page"
                component={Login}
              />
              <PublicRoute
                exact
                path="/forgot-password/set-password"
                name="Set Password Page"
                component={ResetPassword}
              />
              <PublicRoute
                exact
                path="/forgot-password/enter-token"
                name="Enter OTP Page"
                component={EnterResetOTP}
              />
              <PublicRoute
                exact
                path="/forgot-password"
                name="Forgot Password Page"
                component={ForgotPassword}
              />
              <PublicRoute
                exact
                path="/verify-email"
                name="Verify Email Page"
                component={VerifyEmail}
              />
              <PrivateRoute path="/" name="Home" component={TheLayout} />
            </Switch>
          </React.Suspense>
        </HashRouter>
      </Hydrator>
    );
  }
}

export default App;
