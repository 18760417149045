import React from "react";
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { useSelector, useDispatch } from "react-redux";
import ServerErrors from "../../shared/ServerErrors";
import { IState } from "../../redux/reducer/initialState";
import ResetPasswordForm from "../../component/forms/ResetPasswordForm";
import { PassResetActions } from "../../redux/actions/passResetActions";
import { useHistory } from "react-router-dom";
import { decryptionKey } from "../../utils";

const ResetPassword = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { message, errors, isPasswordReset } = useSelector(
    (state: IState) => state.resetPass
  );

  let user: any;
  let userString;

  try {
    const params = history.location.search.split("user=");
    userString = params[1];
    user = JSON.parse(decryptionKey(userString));
  } catch {
    history.replace(`/forgot-password`);
  }

  React.useEffect(() => {
    dispatch(PassResetActions({ resetToken: user.resetToken }));
  }, []);

  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <ServerErrors
        Actions={() => dispatch(PassResetActions())}
        message={message}
        errors={errors}
        condition={isPasswordReset}
      />
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="8">
            <CCardGroup>
              <CCard className="p-4">
                <ResetPasswordForm user={user} />
              </CCard>
              <CCard
                className="text-white bg-primary py-5 d-md-down-none"
                style={{ width: "44%" }}
              >
                <CCardBody className="text-center d-flex justify-content-center align-items-center">
                  <CIcon
                    className="c-sidebar-brand-full"
                    name="logo-negative"
                    width={55}
                  />

                  <h1>Syarpa</h1>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default ResetPassword;
