import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { IState } from "../redux/reducer/initialState";

const PublicRoute = ({ component: Component, ...props }: any) => {
  const auth = useSelector(({ auth }: IState) => auth);

  return (
    <Route
      {...props}
      render={(innerProps) =>
        auth.isAuthenticated ? (
          <Redirect to="/dashboard" />
        ) : (
          <Component auth={auth} {...innerProps} />
        )
      }
    />
  );
};

export default PublicRoute;
