import Actions, { ActionParam } from "./actionTypes";
import { saveToLocalStorage, setAuthToken, fetchData } from "../../utils";
import { AuthActions } from "./authActions";
import {
  ForgotPassReq,
  ForgotPassRes,
  ForgotPassReqProps,
  ResetPassReq,
  ResetPassReqProps,
} from "../../models/PasswordReset";
import { AuthRes } from "../../models/Auth";

export const PassResetActions = (payload = {}, type = Actions.EDIT_RESET) => ({
  type,
  payload,
});

export const ForgotPasswordAsync =
  ({ cb, params }: ActionParam) =>
  async (dispatch: Function) => {
    dispatch(PassResetActions({ isLoading: true, isResetLinkSent: false }));

    const { data, error }: { data?: any; error?: any } = await fetchData({
      method: "POST",
      path: "auth/admin/request-reset-password",
      payload: ForgotPassReq(params as ForgotPassReqProps),
      model: ForgotPassRes,
    });

    if (data) {
      dispatch(
        PassResetActions({
          isResetLinkSent: true,
          errors: null,
          message: "Reset OTP sent successfully to Your Email",
        })
      );
      cb?.(data);
    } else {
      dispatch(
        PassResetActions({
          errors: error.errors,
          message: error.message,
          isResetLinkSent: false,
        })
      );
      cb?.(null);
    }
  };

export const ResetPasswordAsync =
  ({ cb, params }: ActionParam) =>
  async (dispatch: Function) => {
    dispatch(PassResetActions({ isLoading: true, isPasswordReset: false }));
    const { data, error }: { data?: any; error?: any } = await fetchData({
      method: "POST",
      path: "auth/admin/reset-password",
      payload: ResetPassReq(params as ResetPassReqProps),
      model: AuthRes,
    });

    if (data) {
      const user = { ...data, email: params.email };
      saveToLocalStorage({ obj: user, key: "user", isJson: true });
      setAuthToken(data.token);
      dispatch(
        PassResetActions({
          isPasswordReset: true,
          message: "Password Reset Successful",
        })
      );
      dispatch(
        AuthActions({
          user,
          isAuthenticated: true,
        })
      );
      cb?.(data);
    } else {
      dispatch(
        PassResetActions({
          errors: error.errors,
          message: error.message,
          isPasswordReset: false,
        })
      );
      cb?.(null);
    }
  };
