import * as Yup from "yup";

export const loginValidationSchema = Yup.object({
  email: Yup.string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: Yup.string("")
    .min(8, "Password must contain at least 8 characters")
    .required("Enter your password"),
});

export const resetPassValidationSchema = Yup.object({
  password: Yup.string("")
    .min(8, "Password must contain at least 8 characters")
    .required("Enter your password"),
  confirmPassword: Yup.string("")
    .required("Enter your password")
    .oneOf([Yup.ref("password")], "Password does not match"),
});

export const forgotPassValidationSchema = Yup.object({
  email: Yup.string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
});

export const verifyEmailValidationSchema = Yup.object({
  email: Yup.string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  emailVerificationToken: Yup.string("")
    .min(6, "Email verification token must contain 6 characters")
    .required("Enter your email verification token"),
});
