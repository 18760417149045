import React from "react";
import {
  CCardBody,
  CForm,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CInput,
  CRow,
  CCol,
  CButton,
  CInputGroupAppend,
  CContainer,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../redux/reducer/initialState";
import { resetPassValidationSchema } from "../../validations/auth";
import { useFormik } from "formik";
import { ResetPasswordAsync } from "../../redux/actions/passResetActions";
import PasswordMeter from "../molecules/PasswordMeter";

const ResetPasswordForm = ({ user }: any) => {
  const [obscurePass, setObscurePass] = React.useState(true);
  const [passError, setPassError] = React.useState("");

  const { isLoading, resetToken } = useSelector(
    (state: IState) => state.resetPass
  );

  const dispatch = useDispatch();
  const { values, touched, errors, ...formik } = useFormik({
    validationSchema: resetPassValidationSchema,
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    onSubmit: (vals) => {
      // if (!passError || passError === "Password is strong") {
      if (vals.confirmPassword !== vals.password) {
        return alert("Password and ConfirmPassword are not thesame!!!");
      }
      dispatch(
        ResetPasswordAsync({
          params: { resetToken, email: user.email, ...vals },
        })
      );
    },
    // },
  });
  const change = (e: any) => {
    e.persist();
    formik.handleChange(e);
    formik.setFieldTouched(e.target.name, true, false);
  };
  return (
    <CCardBody>
      <CForm onSubmit={formik.handleSubmit}>
        <h1>
          {resetToken
            ? "Enter new password"
            : "Reset Password Token is Invalid or has Expired"}
        </h1>
        {resetToken && (
          <p className="text-muted">Enter your Email to reset your password</p>
        )}
        <CInputGroup className="mt-4">
          <CInputGroupPrepend>
            <CInputGroupText>
              <CIcon name="cil-lock-locked" />
            </CInputGroupText>
          </CInputGroupPrepend>
          <CInput
            type={obscurePass ? "password" : "text"}
            name="password"
            onChange={(e) => change(e)}
            placeholder="Password"
            autoComplete="password"
          />
          <CInputGroupAppend onClick={() => setObscurePass(!obscurePass)}>
            <CInputGroupText
              className={obscurePass ? "" : "bg-dark text-light"}
            >
              <CIcon name="cil-low-vision" />
            </CInputGroupText>
          </CInputGroupAppend>
        </CInputGroup>
        <CContainer className="p-0">
          <PasswordMeter
            password={values.password}
            onChange={(val: any) => setPassError(val)}
          />
        </CContainer>
        <div className="d-inline-block invalid-feedback mb-3">
          {touched.password ? passError || errors.password : ""}
        </div>
        <CInputGroup className="mb-4">
          <CInputGroupPrepend>
            <CInputGroupText>
              <CIcon name="cil-lock-locked" />
            </CInputGroupText>
          </CInputGroupPrepend>
          <CInput
            type="password"
            name="confirmPassword"
            onChange={(e) => change(e)}
            placeholder="confirm Password"
            autoComplete="password"
          />
          <div className="d-inline-block invalid-feedback">
            {touched.confirmPassword ? errors.confirmPassword : ""}
          </div>
        </CInputGroup>
        <CRow>
          <CCol xs="6">
            <CButton
              type="submit"
              color="primary"
              className="d-flex justify-content-between px-2"
            >
              {isLoading ? (
                <div
                  style={{ width: "20px", height: "20px" }}
                  className="spinner-border"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <span />
              )}
              <span className="mx-3">Submit</span>
            </CButton>
          </CCol>
        </CRow>
      </CForm>
    </CCardBody>
  );
};

export default ResetPasswordForm;
