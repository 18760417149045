import { ProfileResProps } from "./Profile";

export interface LoginReqProps {
  password: string;
  email: string;
}

export interface VerifyEmailReqProps {
  email: string;
  emailVerificationToken: string;
}

export function LoginReq(req: LoginReqProps, json = req) {
  return {
    emailAddress: json.email,
    ConfirmPassword: json.password,
    password: json.password,
  };
}
export function VerifyEmailReq(req: VerifyEmailReqProps, json = req) {
  return {
    email: json.email,
    emailVerificationToken: json.emailVerificationToken,
  };
}

export interface AuthResProps {
  expires_in: number;
  refresh_token: string;
  token: string;
  has_two_factor: boolean;
  isVerified: boolean;
  membershipNumber: string;
  twoFactorAuthType: string;
  level: number;
  userId: string;
  username: string;
  role: string;
}

export function AuthRes(res: any): AuthResProps {
  return {
    expires_in: res.expiresIn,
    refresh_token: res.refreshToken,
    token: res.token,
    has_two_factor: res.hasTwoFactorAuthentication,
    isVerified: res.isVerified,
    membershipNumber: res.membershipNumber,
    twoFactorAuthType: res.twoFactorAuthType,
    level: res.level,
    userId: res.userId,
    username: res.username,
    role: res.role,
  };
}

export interface AuthState {
  user?: UserProps;
  isAuthenticated: boolean;
  errors: any;
  profile?: ProfileResProps;
  message?: string;
  isSettingAuth: boolean;
  isLoading: boolean;
}

export interface UserProps {
  expiresIn: number;
  refreshToken: string;
  token: string;
  email: string;
}
