export default class Actions {
  static EDIT_DASHBOARD = "EDIT_DASHBOARD";
  static EDIT_USERS = "EDIT_USERS";
  static EDIT_RESET = "EDIT_RESET";
  static EDIT_AUTH = "EDIT_AUTH";
  static EDIT_OTP = "EDIT_OTP";
  static EDIT_TRANX = "EDIT_TRANX";
  static EDIT_CHARGE = "EDIT_CHARGE";
  static EDIT_TICKETS = "EDIT_TICKETS";
  static EDIT_REFUNDS = "EDIT_REFUNDS";
  static EDIT_DOCUMENTS = "EDIT_DOCUMENTS";
  static EDIT_RATES = "EDIT_RATES";
  static EDIT_FIATS = "EDIT_FIATS";
  static EDIT_FIATS_TRANSACTIONS = "EDIT_FIATS_TRANSACTIONS";
  static EDIT_REFERRALS = "EDIT_REFERRALS";
  static EDIT_DEPARTMENTS = "EDIT_DEPARTMENTS";
  static EDIT_ROLES = "EDIT_ROLES";
  static EDIT_PERMISSIONS = "EDIT_PERMISSIONS";
  static EDIT_SERVICES = "EDIT_SERVICES";
  static EDIT_ACCOUNT_NUMBER = "EDIT_ACCOUNT_NUMBER";
}

export interface ActionParam {
  params?: any;
  cb?: Function;
}
