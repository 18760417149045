import React from "react";
import { CToast, CToaster } from "@coreui/react";

const Toaster = ({ show, message }) => {
  return (
    <CToaster position="top-right">
      <CToast show={show} autohide={5000} fade={true}>
        {message}
      </CToast>
    </CToaster>
  );
};

export default Toaster;
