import Actions from "../actions/actionTypes";
import { charges } from "./initialState";

export default function (state = charges, action: any) {
  switch (action.type) {
    case Actions.EDIT_CHARGE:
      return {
        ...state,
        isLoading: false,
        errors: null,
        message: null,
        ...action.payload,
      };
    default:
      return state;
  }
}
