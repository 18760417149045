import { legacy_createStore as createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";
import reducers from "./reducer";

// const store = createStore(
//   reducers,
//   composeWithDevTools(applyMiddleware(thunkMiddleware))
// );
const store = createStore(
  reducers,
  composeWithDevTools(
    applyMiddleware(thunkMiddleware)
    // other store enhancers if any
  )
);
export default store;
