export interface ForgotPassReqProps {
  email: string;
}

export function ForgotPassReq(req: ForgotPassReqProps, json = req) {
  return {
    email: json.email,
    // ResetPasswordUrl: `${process.env.REACT_APP_APP_URL}/forgot-password/set-password`,
  };
}

export interface ForgotPassResProps {
  code: string;
}

export function ForgotPassRes(res: any): ForgotPassResProps {
  return {
    code: res.ResponseCode,
  };
}

export interface ResetPassReqProps {
  resetToken: string;
  email: string;
  confirmPassword: string;
  password: string;
}

export function ResetPassReq(req: ResetPassReqProps, json = req) {
  return {
    passwordResetToken: json.resetToken,
    email: json.email,
    ConfirmPassword: json.confirmPassword,
    password: json.password,
  };
}

export interface ResetPassState {
  resetToken?: string;
  isPasswordReset: boolean;
  errors: any;
  message?: string;
  isResetLinkSent: boolean;
  isLoading: boolean;
}
