/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-underscore-dangle */
import axios from "axios";
import { format, parseISO } from "date-fns";
import CryptoJS from "crypto-js";
import { SignOutAsync } from "../redux/actions/authActions";
import Cookies from "js-cookie";
import DateRangePicker from "rsuite/DateRangePicker";
import { useState, useEffect } from "react";
const { afterToday } = DateRangePicker;

export const saveToLocalStorage = ({ obj, key, isJson = false }) => {
  if (obj) {
    localStorage.setItem(key, isJson ? JSON.stringify(obj) : obj);
  }
};
export const getDate = (val) => {
  try {
    const date = new Date(val).toUTCString();
    return date;
  } catch (e) {
    return val;
  }
};
export const getLocalStorage = ({ key, isJson = false }) => {
  const val = localStorage.getItem(key);
  if (!val) return null;
  return isJson ? JSON.parse(val) : val;
};

export const clearLocalStorage = ({ key }) => {
  localStorage.removeItem(key);
};

export const fakeEvent = (name, value, ...rest) => ({
  target: { name, value },
  persist: (_) => _,
  ...rest[0],
});

export const emptyObj = (obj = {}) => {
  for (const _i in obj) return false;
  return true;
};

export const axiosCall = async ({
  method = "GET",
  host = process.env.REACT_APP_SERVER,
  path = "",
  payload = {},
}) => {
  const url = `${host}/${path}`;
  const headers = {
    "Content-Type": "application/json",
    "X-ClientSecret": process.env.REACT_APP_CLIENT_SECRET,
    "x-skarpa-client": process.env.REACT_APP_ADMIN_SECRET,
  };

  const axiosData = {
    method,
    url,
    data: payload,
    headers,
    json: true,
    /* httpsAgent: new https.Agent({
      rejectUnauthorized: false,
    }), */
  };

  return axios(axiosData);
};

export const convertDate = (arg) => {
  return format(parseISO(arg), "MM/dd/yyyy");
};

export const convertToCurrency = (str) =>
  Number(str)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,");

export async function fetchData({ model, extra = {}, ...args }) {
  try {
    const res = await axiosCall(args);
    const { data, Data } = res.data;
    return { data: model(data || Data || res.data, extra) };
  } catch (err) {
    console.log("err =>", err);
    try {
      const { data: data_1 } = err.response;
      if (
        data_1?.ResponseCode === "04" ||
        data_1?.ResponseMessage ===
          "RefreshToken does not exist / is not usable for this user"
      ) {
        clearLocalStorage({ key: "user" });
        window.location.reload();
      }
      return {
        error:
          data_1.ResponseMessage || data_1.message
            ? { message: data_1.ResponseMessage || data_1.message }
            : { errors: data_1.errors },
      };
    } catch (e) {
      console.log(e);
      return {
        error: { message: "Something went wrong" },
      };
    }
  }
}

export async function simpleFetchData(method, path, payload = {}) {
  try {
    const setting = {
      method,
      host: process.env.REACT_APP_UMS,
      path,
      payload,
    };
    const res = await axiosCall(setting);
    const data = res.data;
    return data;
  } catch (err) {
    const { data: data_1 } = err.response;
    return {
      error:
        data_1.ResponseMessage || data_1.message
          ? { message: data_1.ResponseMessage || data_1.message }
          : { errors: data_1.errors },
    };
  }
}

export const decryptionKey = (encrypted) => {
  try {
    const decrypted = CryptoJS.AES.decrypt(
      encrypted,
      CryptoJS.enc.Utf8.parse(process.env.REACT_APP_RESET_PASS_DECRYPTION_KEY),
      {
        iv: CryptoJS.enc.Utf8.parse("zT$3qIjUR$4rIj45"),
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC,
      }
    );

    return decrypted.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    return "";
  }
};

export const encryptKey = (value) => {
  try {
    const encrypted = CryptoJS.AES.encrypt(
      value,
      CryptoJS.enc.Utf8.parse(process.env.REACT_APP_RESET_PASS_DECRYPTION_KEY),
      {
        iv: CryptoJS.enc.Utf8.parse("zT$3qIjUR$4rIj45"),
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC,
      }
    );

    return encrypted.toString();
  } catch (error) {
    return "";
  }
};
function refreshToken() {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const originalRequest = error.config;
      if (
        (error.response?.status === 401 ||
          error.response?.message === "Token has expired" ||
          error.response?.data?.message === "Token has expired") &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;
        const user = getLocalStorage({ key: "user", isJson: true });
        return axiosCall({
          path: `Auth/RefreshToken`,
          method: "POST",
          payload: {
            Token: user.token,
            RefreshToken: user.refreshToken,
            ExpiresIn: 0,
          },
        }).then((res) => {
          if (res.status === 200) {
            // 1) put token to LocalStorage
            const { Data } = res.data;
            user.refreshToken = Data.RefreshToken;
            user.token = Data.Token;
            saveToLocalStorage({ obj: user, key: "user", isJson: true });

            // 2) Change Authorization header
            axios.defaults.headers.common.Authorization = `Bearer ${Data.Token}`;

            originalRequest.headers.Authorization = `Bearer ${Data.Token}`;
            // 3) return originalRequest object with Axios.
            return axios(originalRequest);
          }
        });
      }

      // return Error object with Promise
      return Promise.reject(error);
    }
  );
}
export const setToken = (value) => {
  // var date = new Date(new Date().getTime() + 100 * 30 * 100);
  var date = new Date(new Date().getTime() + 3600 * 1000); //1 hour
  Cookies.set("__token", value, { expires: date });
};
export const getToken = () => {
  const jwt = Cookies.get("__token");
  let session = undefined;
  try {
    if (jwt !== undefined) {
      session = jwt;
    }
  } catch (error) {
    // clearToken()
    // SignOutAsync()
  }
  return session;
};

export const clearToken = () => {
  Cookies.remove("__token");
};
export const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common.Authorization;
  }
  SignOutAsync();
  // window.location.href = "/login"
  // window.location.replace("/login")
  // refreshToken();
};

export const formatPhone = (phone, dialcode) => {
  if (phone.isEmpty) return phone;
  return (
    String(dialcode).replace(RegExp("[^0-9]"), "") +
    (phone[0] === "0" ? phone.substring(1, phone.length) : phone)
  );
};

export const truncate = (input, length = 30) =>
  input && input.length > length ? `${input.substring(0, length)}...` : input;

export const getTransferStatus = (status) => {
  switch (status) {
    case "10":
      return "Awaiting Payment";
    case "20":
      return "Processing Payout";
    case "30":
      return "Completed";
    case "40":
      return "Declined";
    case "50":
      return "Refunded";
    case "51":
      return "Requesting Refund";
    case "52":
      return "Processing Refund";
    case "60":
      return "Failed";
    case "70":
      return "Cancelled";
    case "80":
      return "Requested";
    case "90":
      return "Initiated";
    default:
      return status;
  }
};

export const RsDateRangePicker = (props) => {
  const [date, setDate] = useState(props?.defaultValue);
  return (
    <DateRangePicker
      ranges={[]}
      // format="dd MMM, yyyy"
      placeholder="YYYY-MM-DD - YYYY-MM-DD"
      value={date}
      onChange={(value) => setDate(value)}
      caretAs={() => (
        <svg
          width="1.25em"
          height="1.25em"
          viewBox="0 0 18 18"
          fill="currentColor"
          aria-hidden="true"
          focusable="false"
          class="rs-picker-toggle-caret rs-icon"
          aria-label="calendar"
          data-category="legacy"
        >
          <path
            d="M13.167 2.5H16.5003C16.7213 2.5 16.9333 2.5878 17.0896 2.74408C17.2459 2.90036 17.3337 3.11232 17.3337 3.33334V16.6667C17.3337 16.8877 17.2459 17.0996 17.0896 17.2559C16.9333 17.4122 16.7213 17.5 16.5003 17.5H1.50033C1.27931 17.5 1.06735 17.4122 0.91107 17.2559C0.75479 17.0996 0.666992 16.8877 0.666992 16.6667V3.33334C0.666992 3.11232 0.75479 2.90036 0.91107 2.74408C1.06735 2.5878 1.27931 2.5 1.50033 2.5H4.83366V0.833336H6.50033V2.5H11.5003V0.833336H13.167V2.5ZM11.5003 4.16667H6.50033V5.83334H4.83366V4.16667H2.33366V7.5H15.667V4.16667H13.167V5.83334H11.5003V4.16667ZM15.667 9.16667H2.33366V15.8333H15.667V9.16667Z"
            fill="#000"
          />
        </svg>
      )}
      disabled={props.disabled}
      character=" - "
      contentEditable={false}
      {...props}
      shouldDisableDate={afterToday()}
    />
  );
};

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}

export const allRoles = [
  { label: "Super_Admin", value: "Super_Admin" },
  { label: "Admin", value: "Admin" },
  { label: "Support", value: "Support" },
];
