import React, { useEffect, useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Redirect, useHistory } from "react-router-dom";
import { SignOutAsync } from "../redux/actions/authActions";
import { IState } from "../redux/reducer/initialState";
import { getToken } from "../utils";
import Cookies from 'js-cookie';

const PrivateRoute = ({ component: Component,path:path, ...props }: any) => {
  const auth = useSelector(({ auth }: IState) => auth);
  const dispatch = useDispatch()
  // const __token = useMemo(()=> getToken(), [])
  const __token = getToken()
  var reload = ()=> {

    dispatch(SignOutAsync())
    // localStorage.removeItem('user')
    // window.location.reload()
  }
  useEffect(()=> {
    __token === undefined && reload()
  })
  return (
    <Route
      path={path}
      {...props}
      render={(innerProps) =>
        auth.isAuthenticated && __token !== undefined? (
          <Component auth={auth} {...innerProps} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
 
};

export default PrivateRoute;
