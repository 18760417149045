import React from "react";
import zxcvbn from "zxcvbn";
import "./passwordMeter.scss";

const validatePasswordStrong = ({
  value,
  minStrength,
  thresholdLength,
  onChange,
}: any) => {
  let output = "";
  if (value.length <= thresholdLength) output = "Password is short";
  else if (zxcvbn(value).score < minStrength) output = "Password is weak";
  else if (zxcvbn(value).score === minStrength) output = "Password is strong";
  else output = "";
  onChange(output);
};

const PasswordMeter = ({ password = "", onChange }: any) => {
  const minStrength = 3;
  const thresholdLength = 7;
  const [strength, setStrength] = React.useState(0);

  React.useEffect(() => {
    setStrength(zxcvbn(password).score);
    validatePasswordStrong({
      value: password,
      minStrength,
      thresholdLength,
      onChange,
    });
  }, [password]);

  const passwordLength = password.length;

  const strengthClass = [
    "strength-meter",
    passwordLength > 0 ? "visible" : "invisible",
  ]
    .join(" ")
    .trim();

  return (
    <div className={strengthClass}>
      <div
        className="strength-meter-fill"
        data-strength={password === "" ? -1 : strength}
      />
    </div>
  );
};

export default PasswordMeter;
