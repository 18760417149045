import { combineReducers } from "redux";
import auth from "./authReducer";
import dashboard from "./dashboardReducer";
import transactions from "./tranxReducer";
import users from "./usersReducer";
import tickets from "./ticketReducer";
import refunds from "./refundReducer";
import resetPass from "./resetPassReducer";
import charges from "./chargeReducer";
import otp from "./otpReducer";
import rates from "./rateReducer"
import fiats from "./fiatReducer"
import fiatTransactions from "./fiatTransactionReducer"
import departments  from "./dapartmentReducer";
import roles from "./roleReducer";
import permissions from "./permissionReducer";
import services from "./serviceReducer";

const reducers = combineReducers({
  auth,
  dashboard,
  transactions,
  users,
  tickets,
  refunds,
  resetPass,
  otp,
  charges,
  rates,
  fiats,
  fiatTransactions,
  departments,
  roles,
  permissions,
  services
});

export default reducers;